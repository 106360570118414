/* eslint-disable */
import React from "react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/components/navigation/navigation.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import Intro from "../../components/intro/index";
import HomeData from "../../data/home.json";
import Wrapper from "../../components/wrapper/wrapper";
import Title from "../../components/title/title";
import Subtitle from "../../components/subtitle/subtitle";
import classes from "../navbar-slider/NavbarSlider.module.css";
import HeroSlider, { Slide, MenuNav, OverlayContainer } from "hero-slider";

SwiperCore.use([Pagination]);
const IntroContainer = () => {
    const nextSlideHandler = React.useRef();
    const previousSlideHandler = React.useRef();
    const swiperOption = {
        loop: true,
        speed: 1500,
        spaceBetween: 0,
        slidesPerView: 1,
        pagination: { clickable: true },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    };
    /* eslint-disable */
    return (
        <div>
            <HeroSlider
                nextSlide={nextSlideHandler}
                previousSlide={previousSlideHandler}
                slidingAnimation="top_to_bottom"
                orientation="vertical"
                initialSlide={1}
                onBeforeChange={(previousSlide, nextSlide) =>
                    console.log("onBeforeChange", previousSlide, nextSlide)
                }
                onChange={(nextSlide) => console.log("onChange", nextSlide)}
                onAfterChange={(nextSlide) =>
                    console.log("onAfterChange", nextSlide)}
                style={{
                    backgroundColor: "#000",
                }}
                settings={{
                    slidingDuration: 400,
                    slidingDelay: 100,
                    shouldAutoplay: true,
                    shouldDisplayButtons: false,
                    autoplayDuration: 8000,
                    height: "100vh"
                }}>



                <OverlayContainer>
                    <Wrapper className={classes.Wrapper_Container}>
                    <Title className="title" >
                        MartinG Photography
                    </Title>
                    
                    <Subtitle style={{color: `#0099cc`}} className="subtitle title">
                        A glimpse of the world from my point of view
                    </Subtitle>
                    </Wrapper>
                </OverlayContainer>
                {HomeData[0].slider &&
                    HomeData[0].slider.map((single, key) => {
                        return (
                            <Slide
                                id = {key}
                                shouldRenderMask
                                navDescription={single.title}
                                background={{
                                backgroundColor: single.backgroundColor,
                                backgroundImage: single.backgroundImage
                                }} 
                    
                            />
                        );
                    })}
                    <MenuNav />
                            </HeroSlider>

            </div>
    );
};

export default IntroContainer;
