import PageTitle from "../../../components/page-title";

const PageTitleContainer = () => {
    return (
        <div className="page-title-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <PageTitle
                            classOption="page-title-content content-style2 text-center"
                            subTitle="About"
                            title="MartinG Photography"
                        />
                    </div>
                </div>
            </div>
            <div className="thumb" data-aos="fade-up" data-aos-delay="300">
                <img
                    className="w-100"
                    src={`http://static.martingtechnologies.com/photography/images/fashion/fashion-51.jpg`}
                    alt="MartinG Photography Image"
                />
            </div>
        </div>
    );
};

export default PageTitleContainer;
