/* eslint-disable */
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import axios from 'axios';
import ReCaptcha from "react-google-recaptcha";

const ContactForm = () => {
    const { register, errors } = useForm({
        mode: "onBlur",
    });
    const [ formState, setFormState ] = useState({name: " ", email: " ", subject: " ", message: " "})
    const resetForm = () =>{
        setState({name: '', email: '',subject:'', message: ''})
    }
    const submitEmail = (e) =>{
        e.preventDefault();
        console.log('getting input', e)
        
        axios({
          method: "POST", 
          url:"https://vncjrtvltb.execute-api.us-east-1.amazonaws.com/test/contact", 
          data:  formState
        }).then((response)=>{
            console.log("got a message");
            console.log(response);
          if (response.data.status === "success"){
              alert("Message Sent."); 
              resetForm()
          }else if(response.data.status === "fail"){
              alert("Message failed to send.")
          }
        })
}
    return (
        <Fragment>
            <form
                className="contact-form-wrapper"
                onSubmit={submitEmail} 
                method="POST"
            >
                <div className="row">
                    <div
                        className="col-md-4"
                        data-aos="fade-up"
                        data-aos-delay="300"
                    >
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                name="name"
                                onChange={(e) => setFormState({...formState, name: e.target.value})}
                                placeholder="Your Name"
                                ref={register({ required: "Name is required" })}
                            />
                            {errors.name && <p>{errors.name.message}</p>}
                        </div>
                    </div>
                    <div
                        className="col-md-4"
                        data-aos="fade-up"
                        data-aos-delay="600"
                    >
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="email"
                                name="email"
                                onChange={(e) => setFormState({...formState, email: e.target.value})}
                                placeholder="Email Address"
                                ref={register({
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "invalid email address",
                                    },
                                })}
                            />
                            {errors.email && <p>{errors.email.message}</p>}
                        </div>
                    </div>
                    <div
                        className="col-md-4"
                        data-aos="fade-up"
                        data-aos-delay="900"
                    >
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                name="subject"
                                onChange={(e) => setFormState({...formState, subject: e.target.value})}
                                placeholder="Subject"
                                ref={register({
                                    required: "Subject is required",
                                })}
                            />
                            {errors.subject && <p>{errors.subject.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-12" data-aos="fade-up">
                        <div className="form-group mb-0">
                            <textarea
                                name="message"
                                rows="5"
                                onChange={(e) => setFormState({...formState, message: e.target.value})}
                                placeholder="Your message here..."
                                ref={register({
                                    required: "Message is required",
                                })}
                            ></textarea>
                            {errors.message && <p>{errors.message.message}</p>}
                        </div>
                    </div>
                    <div
                        className="col-md-12 text-center"
                        data-aos="fade-up"
                        data-aos-delay="300"
                    >
                        <ReCaptcha style={{margin: `auto`, display: `inline-block`}}  id="captcha_element"
                                    sitekey="6LfZ_78ZAAAAAHnKD1abNgw9dJFN2zrTYOtJRkJ8"
                                    theme="dark"
                                    
                                />
                        <div className="form-group mb-0">
                            <button className="btn-submit" type="submit">
                                Send Message
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default ContactForm;
