import AboutData from "../../../data/global/about.json";
import styled from 'styled-components';
const LogoImg = styled.img `
    
    padding: 0 0px;
    height: 75%;
    max-width: 50%;

`
const BlockquoteSingle = (props) => {
    console.log(props);
    return (
        <div className="blockquote-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <blockquote className="blockquote-style">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: AboutData[props.id].excerpt,
                                }}
                            />
                            <div className="author-info">
                                <LogoImg src={AboutData[props.id].image}/>
                                {/* <span className="job">
                                    {AboutData[props.id].designation}
                                </span> */}
                            </div>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    );
};
// BlockquoteSingle.propTypes = {
//     id: PropTypes.node,
// }

export default BlockquoteSingle;
